import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayoutNew';
import DashboardLayoutMap from 'src/layouts/DashboardLayoutMap';
import MainLayout from 'src/layouts/MainLayout';
import BlogLayout from 'src/layouts/BlogLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/Guards/AuthGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import EventGuard from 'src/components/Guards/EventGuard';
import HomeView from 'src/views/home/HomeView';
// import UserGuard from 'src/components/Guards/UserGuard';
// import SettingsLayout from 'src/views/Test';
// import SettingsGuard from 'src/components/Guards/SetingsGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
 
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() => import('src/views/auth/LoginView/Confirm_forgot_password'))
  },
{
  path: '/events',
  guard: EventGuard,
  layout: BlogLayout,

  routes: [{
    exact: true,
    path: '/events/blog',
    component: lazy(() => import('src/views/Blogs'))
  },
  {
    exact: true,
    path: '/events/blog/create',
    component: lazy(() => import('src/views/BlogsCreate'))
  },]
},
  {
    path: '/apps',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      
     
      {
        exact: true,
        path: '/apps/mapview',
        component: lazy(() => import('src/views/Dashboard'))
      },
      {
        exact: true,
        path: '/apps/dashboard',
        component: lazy(() => import('src/views/Booth'))
      },
      {
        exact: true,
        path: '/apps/Results',
        component: lazy(() => import('src/views/Booth/Results'))
      },
      {
        exact: true,
        path: '/apps/voters',
        component: lazy(() => import('src/views/VotersList'))
      },
      {
        exact: true,
        path: '/apps/beaemployes',
        component: lazy(() => import('src/views/BAEmployes'))
      },
      {
        exact: true,
        path: '/apps/booths',
        component: lazy(() => import('src/views/Booths'))
      },
      
      {
        exact: true,
        path: '/apps/wards',
        component: lazy(() => import('src/views/Wards'))
      },
      {
        exact: true,
        path: '/apps/Analysis',
        component: lazy(() => import('src/views/Analysis'))
      },
      
      // {
      //   exact: true,
      //   path: '/apps/corporators',
      //   component: lazy(() => import('src/views/CorporatorList'))
      // },
      {
        exact: true,
        path: '/apps/leaders',
        component: lazy(() => import('src/views/Leaders'))
      },    
      {
        exact: true,
        path: '/apps/events',
        component: lazy(() => import('src/views/Event'))
      },
      {
        exact: true,
        path: '/apps/socialMedia',
        component: lazy(() => import('src/views/SocialMedia'))
      },
      {
        exact: true,
        path: '/apps/events/blog',
        component: lazy(() => import('src/views/Blogs'))
      },
      {
        exact: true,
        path: '/apps/events/blog/create',
        component: lazy(() => import('src/views/BlogsCreate'))
      },
      // {
      //   exact: true,
      //   path: '/apps/detail/canvasing',
      //   component: lazy(() => import('src/views/Canvasing'))
      // },
      // {
      //   exact: true,
      //   path: '/apps/detail/committee',
      //   component: lazy(() => import('src/views/Committe'))
      // },
    
        {
        exact: true,
        path: '/apps/detail/:type/:id',
        component: lazy(() => import('src/views/Booth'))
      },
    
      
    ]
  },
  {
    path: '/apps',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [ 
     
    
      {
        exact: true,
        path: '/apps/detail/:ward/:booth/voters',
        component: lazy(() => import('src/views/VotersList'))
      },
      // {
      //   exact: true,
      //   path: '/apps/detail/:ward/:booth/corporators',
      //   component: lazy(() => import('src/views/CorporatorList'))
      // },
      {
        exact: true,
        path: '/apps/detail/:ward/:booth/events',
        component: lazy(() => import('src/views/Event'))
      },
   
      // {
      //   exact: true,
      //   path: '/apps/detail/:ward/:booth/canvasing',
      //   component: lazy(() => import('src/views/Canvasing'))
      // },
      // {
      //   exact: true,
      //   path: '/apps/detail/:ward/:booth/committee',
      //   component: lazy(() => import('src/views/Committe'))
      // },
      // {
      //   exact: true,
      //   path: '/apps/detail/:ward/:booth/finance',
      //   component: lazy(() => import('src/views/Finance'))
      // },
      
      
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
