import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import Cookies from 'js-cookie';
import AWS from 'aws-sdk';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
require('dotenv').config();

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  institution: null,
  image: null
};

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  //const decoded = jwtDecode(accessToken);
  //const currentTime = Date.now() / 1000;

  return true;
};

const setSession = (accessToken, refreshToken, idToken) => {
  if (accessToken) {
    Cookies.set('accessToken', accessToken);
    //localStorage.setItem('idToken',idToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.refreshToken = refreshToken;
    axios.defaults.headers.common.idToken = idToken;
  } else {
    Cookies.remove('accessToken');
    Cookies.remove('rfsrt');
    Cookies.remove('idToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FB_POST': {
      const { message, user } = action.payload;

      return {
        ...state,
        message,
        user
      };
    }

    default: {
      return { ...state };
    }
  }
};

const CompanyContext = createContext({
  ...initialAuthState,
  getFbPosts: () => Promise.resolve()
});

export const CompanyProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const getFbPosts = async data => {
    try {
      console.log(data)
      let response;

      if (process.env.REACT_APP_ENV === 'test') {
        response = await fetch(
          'https://e3ga1vl4v0.execute-api.ap-south-1.amazonaws.com/facebook_posts',

          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              fbaccesstoken: data
            },
            body: JSON.stringify({
              data: {}
            })
          }
        )
          .then(response => response.json())
          .then(data =>
            dispatch({
              type: 'FB_POST',
              payload: {
                user: data,
                message: data
              }
            })
          )
          .catch(err => console.log(err));
      } else {        
        response = await fetch(
          'https://e3ga1vl4v0.execute-api.ap-south-1.amazonaws.com/facebook_posts',

          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              fbaccesstoken: data
            },
            body: JSON.stringify({
              data: {}
            })
          }
        )
          .then(response => response.json())
          .then(data =>
            dispatch({
              type: 'FB_POST',
              payload: {
                user: data,
                message: data
              }
            })
          )
          .catch(err => console.log(err));
      }
      console.log(response);

      dispatch({
        type: 'FB_POST',
        payload: {
          user: response.payload.data,
          message: response
        }
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        ...state,

        getFbPosts
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyContext;
