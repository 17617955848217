/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Logo from 'src/components/Logo';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import {
  Box,
  Divider,
  Drawer,
  Chip,
  Avatar,
  AppBar,
  Toolbar,
  IconButton,
  List,
  SvgIcon,
  ListSubheader,
  Typography,
  useMediaQuery,
  makeStyles,
  useTheme,
  Link,
  withStyles,
  Button
} from '@material-ui/core';
import Account from './Account';
import { Link as RouterLink } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/SchoolOutlined';
import Search from './Search';
import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import NavItem from './NavItem';
import Notifications from './Notifications';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MenuIcon from '@material-ui/icons/Menu';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CommentIcon from '@material-ui/icons/Comment';
import InboxIcon from '@material-ui/icons/Inbox';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FacebookIcon from '@material-ui/icons/Facebook';
import MailIcon from '@material-ui/icons/Mail';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useHistory } from 'react-router-dom';
import MapIcon from '@material-ui/icons/Map';

const GreenStyleChip = withStyles({
  root: {
    backgroundColor: '#B5B2F6',
    color: 'white'
  }
})(Chip);
const ColorAppBar = withStyles({
  root: {
    backgroundColor: '#FFFF'
  }
})(AppBar);
const Users = {
  items: [
    // {
    //   title: 'Map View',
    //   href: '/apps/mapview',
    //   icon: HomeIcon
    // },
    {
      title: 'Dashboard',
      href: '/apps/dashboard',
      icon: AccountBoxIcon
    },
    {
      title: 'Election Results',
      href: '/apps/Results',
      icon: AssignmentTurnedInIcon
    },
    {
      title: 'Party Leaders',
      href: '/apps/leaders',
      icon: SupervisorAccountIcon
    },
    // {
    //   title: 'BEA Employes',
    //   href: '/apps/beaemployes',
    //   icon: Diversity3Icon
    // },
    {
      title: 'Voters',
      href: '/apps/voters',
      icon: BookmarkIcon
    },
    // {
    //   title: 'Map',
    //   href: '/apps/constmapview',
    //   icon: MapIcon
    // },
    // {
    //   title: 'Event List',
    //   href: '/apps/events',
    //   icon: DashboardIcon
    // },
    // {
    //   title: 'Social Media',
    //   href: '/apps/socialMedia',
    //   icon: FacebookIcon
    // },
    // {
    //   title: 'Analysis',
    //   href: '/apps/Analysis',
    //   icon: FacebookIcon
    // },
    // {
    //   title: 'Booths',
    //   href: '/apps/booths',
    //   icon: AllInboxIcon
    // },
    // {
    //   title: 'Wards',
    //   href: '/apps/wards',
    //   icon: InboxIcon
    // }
  ]
};

const labs = {
  items: [
    // {
    //   title: 'Send SMS',
    //   href: '/apps/sms',
    //   icon: CommentIcon
    // },
    // {
    //   title: 'Send Mail',
    //   href: '/apps/mail',
    //   icon: MailIcon
    // }
  ]
};

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // backgroundColor: '#f3e5f5'
  },
  root1: {
    display: 'flex',
    paddingLeft: 270
  },
  desktopDrawer: {
    width: 270,
    //top: 64,
    height: '100%'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: {
        enteringScreen: 225,
        leavingScreen: 195
      }
    })
  },

  menuButton: {
    marginRight: theme.spacing(2),
    color: '#000000'
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useRightStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 75,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const useLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 75,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 75
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({
  className,
  open,
  setOpen,
  isMobileNavOpen,
  openMobile,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme1 = useTheme();
  const mobileDevice = useMediaQuery(theme1.breakpoints.down('xs'));

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const [om, setOM] = useState(false);
  const handleDrawer1 = () => {
    if (om) {
      setOM(false);
    } else {
      setOM(true);
    }
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column" style={{
      backgroundColor : "#FFFF"
    }}>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box mb={3} mt={1} ml={1.5} display="flex" alignItems="center">
            <SvgIcon>

            <ThumbsUpDownIcon/>

            </SvgIcon>
            <Box mr={2} />
            <Box mt={0.3}>
              <Typography variant="h5" color="textPrimary">
                ZOI ELECTION
              </Typography>
            </Box>
          </Box>
          <List
          
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname
            })}
          </List>
          {/* <Divider /> */}
          <Box mt={3}/>
          {/* <Box mb={3} mt={1} ml={1.5} display="flex" alignItems="center">
            <LabelIcon/>
            <Box mr={1} />
            <Box mt={0.3}>
              <Typography variant="h5" color="textPrimary">
                labs
              </Typography>
            </Box>
          </Box> */}
            {/* <Box mt={2} /> */}

          <List
          
            key={labs.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {labs.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: labs.items,
              pathname: location.pathname
            })}
          </List>
        </Box>
        
      </PerfectScrollbar>
      <Divider />

      {/* <Box p={1} pl={5} pb={3} borderRadius="borderRadius"
         bgcolor="background.dark"
         >
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              // to="/app/admin/help_section"
              className={classes.action}
            >
              Resolve My Issue
            </Link>
          </Box> */}

      <Divider />
    </Box>
  );

  return (
    <div className={classes.root}>
      {mobileDevice ? (
        <>
          <CssBaseline />

          <ColorAppBar className={om ? classes.root1 : classes.root} {...rest}>
            <Toolbar className={classes.toolbar}>
            

              <Box mr={1} />

              <Box ml={2} flexGrow={1} />
              <Search />

              <Notifications />

              <Box ml={2}>
                <Account />
              </Box>
            </Toolbar>
          </ColorAppBar>
          <Drawer
            classes={{ paper: classes.desktopDrawer }}
            variant="persistent"
            anchor="left"
            open={om}
          >
            {content}
          </Drawer>
        </>
      ) : (
        <>
          <CssBaseline />

          <ColorAppBar
            className={open ? classes.root : classes.root}
            {...rest}
          >
            <Toolbar className={classes.toolbar}>
            <Box  display="flex" alignItems="center">
           
                <IconButton >
                  <ThumbsUpDownIcon fontSize="medium" />
                </IconButton>
                <Box mr = {0.5} />
              <Typography variant="h5" color="textPrimary">
                 ELECTION
              </Typography>
          </Box>
          <Box mr = {4} />
              {
                Users.items.map((item) => {
                  return(
                  <>
                    <Button 
                    variant = 'outlined'
                    onClick={() =>
                    history.push(item.href)
                    }
                    style={{
                      backgroundColor : item.href === location.pathname ? '#81d4fa' : '#eeeeee'
                    }}
                    >
                      {item.title}
                    </Button>
                    <Box mr = {1} />
                  </>
                  );
                })
              }
              {/* {open === true && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer}
                  edge="start"
                  className={clsx(classes.menuButton, open)}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}
              {open === false && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawer}
                  edge="start"
                  className={clsx(classes.menuButton, open)}
                >
                  <MenuOpenIcon fontSize="large" />
                </IconButton>
              )} */}



              <Box mr={1} />

              <Box ml={2} flexGrow={1} />
              <Search />

              <Notifications />

              <Box ml={2}>
                <Account />
              </Box>
            </Toolbar>
          </ColorAppBar>
          {/* <Drawer
            classes={{ paper: classes.desktopDrawer }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            {content}
          </Drawer> */}
        </>
      )}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;