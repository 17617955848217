import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';

const EventGuard = ({ children }) => {

  return (
    <>
      {children}
    </>
  );
};

EventGuard.propTypes = {
  children: PropTypes.node
};

export default EventGuard;
