/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Logo from 'src/components/Logo';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { Link as RouterLink } from 'react-router-dom';
import Contacts from './Contacts';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Chip,
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import Account from './Account';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import TransformOutlinedIcon from '@material-ui/icons/TransformOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import Search from './Search';
import Settings from './Settings';
import {
  Edit as EditIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import NavItem from './NavItem';
import Label from 'src/components/Label';
import Notifications from './Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';

const ColorAppBar = withStyles({
  root: {
      backgroundColor : "#4F4E7D"
  },
})(AppBar);



const Users = {
  subheader: 'ವಾರ್ಡ್ ಗಳು',
  
  items: [
    
 
      // {
      //   title: 'ದೇವರಾಜ್ ಅರಸ್-ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ವಿನೋಬನಗರ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಪಿ.ಜೆ.ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಯಲ್ಲಮ್ಮನಗರ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ನಿಜಲಿಂಗಪ್ಪ-ಬಡವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಎಂ.ಸಿ.ಸಿ ಎ ಬ್ಲಾಕ್ ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಕೆ.ಟಿ.ಜೆ. ನಗರ - 2 ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಕೆ.ಟಿ.ಜೆ. ನಗರ - 1 ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಭಗತ್ ಸಿಂಗ್ ನಗರ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ನಿಟ್ಟುವಳ್ಳಿ ಆಂಜನೇಯ-ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಆವರಗೆರೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಎಸ್.ಜಿ.ಓ ಕಾಲೋನಿ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ನಿಟುವಳ್ಳಿ ಚಿಕ್ಕನಹಳ್ಳಿ-ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಸರಸ್ವತಿ ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಶಿವಕುಮಾರ ಸ್ವಾಮಿ ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ನಿಟುವಳ್ಳಿ ಹೊಸ-ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಲೆನಿನ್ ನಗರ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಕೆ.ಇ.ಬಿ ಕಾಲೋನಿ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಎಂ.ಸಿ.ಸಿ ಬಿ ಬ್ಲಾಕ್',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ವಿದ್ಯಾನಗರ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಆಂಜನೇಯ-ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಬನಶಂಕರಿ-ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಸಿದ್ದವೀರಪ್ಪ-ಬಡಾವಣೆ ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಶಾಮನೂರು',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'ಎಸ್.ಎಸ್-ಬಡಾವಣೆ',
      //   href : '/app/dashboard',
      //   icon: ReceiptOutlinedIcon,
      // },
      // {
      //   title: 'Map View',
      //   href : '/app/dashboard',
      //   icon: HomeOutlinedIcon,
      // },
      {
        title: 'ವಾರ್ಡ್ ನೋಟ',
        href : '/app/detail',
        icon: HomeOutlinedIcon,
      },
      {
          title: 'ಕಾರ್ಪೊರೇಟರ್ ವಿವರಗಳು',
          href : '/app/detail/corporators',
          icon: ReceiptOutlinedIcon,
        },
        {
          title: 'ಮತದಾರರ ವಿವರಗಳು',
          href : '/app/detail/voters',
          icon: AccountBoxOutlinedIcon,
        },
        {
          title: 'ಸಮಿತಿ',
          href : '/app/detail/committee',
          icon: ReceiptOutlinedIcon,
        },
        {
          title: 'ಕಾರ್ಯಕ್ರಮಗಳು',
          href : '/app/detail/events',
          icon: EventNoteOutlinedIcon,
        },
        {
          title: 'ಕ್ಯಾನ್ವಾಸಿಂಗ್',
          href : '/app/detail/canvasing',
          icon: ReceiptOutlinedIcon,
        },
     
     
  ]
};
const seetingsacademic = {
  items: [
    {
    title: 'ಸಂಯೋಜನೆಗಳು',
    href: '/apps/admin/settings',
    icon: SettingsOutlinedIcon,
    items: [
      {
        title: 'ಸಂಯೋಜನೆಗಳು',
        href: '/apps/admin/settings/general'
      },
      // {
      //   title: 'Academic Settings',
      //   href: '/app/admin/settings'
      // },
      // {
      //   title: 'Fee Settings',
      //   href: '/app/admin/settings/fee_settings'
      // },
      // {
      //   title: 'Fee Schedule',
      //   href: '/app/admin/settings/feeschedule'
      // },
    ]
  }
  ]
}

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 310,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const sections = [
  {
    items: [
      {
        icon: PieChartIcon,
        href: <Search />
      },
      {
        icon: BarChartIcon,
        href: <Search />
      }
    ]
  }
];

function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useRightStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const useLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },


  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 70
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({ className, open, setOpen, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const rightClasses = useRightStyles();
  const logoClasses = useLogoStyles();
  const location = useLocation();
  const { user } = useAuth();
  console.log(open);
  let users = '';

  const [image, setImage] = useState(null);
  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  let folderName = user['custom:institution_id']
  var bucketName = "erpinstitution"
  let img
  let logo
  const s3 = new AWS.S3();

  async function get_logo(image_path) {
    let img = await s3
        .getObject({
          Bucket: bucketName,
          Key: image_path
        })
        .promise()
        .then(res => {
          if (res) {
            console.log("logo",res)
            return res;
            
          } else {
            return null;
          }
        })
        .catch(err => {
          console.log(err);
        });
        return img;
  }


  const getCustomers = useCallback(async () => {
    try {
      
      const get_image = gql`
      query get_setup {
        get_setup {
          _id
          institution_logo
        }
      }`;
      var image_path = encodeURIComponent(folderName) + "/" + encodeURIComponent("logo") + "/" +user['custom:institution_id']


      img = await get_logo(image_path)
          if (img === undefined || img === null) {
            logo = null 
          }else {
            var base64Flag = `data:${img.contentType};base64,`;
            var imgData = arrayBufferToBase64(img.Body);
            logo = base64Flag + imgData;
            setImage(logo);
          }
     
      
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);
  
  const handleDrawer = () => {
    if (open) {
      setOpen(false);
      window.localStorage.setItem('drawer',false);
    } else {
      setOpen(true);
      window.localStorage.setItem('drawer',true);
    }
  };

  window.addEventListener('storage', function(e) {
    console.log('storage fired');
    // this.fetchData();
  });
  console.log(window.dispatchEvent(new Event('storage')
  
  ));
  
  window.addEventListener('storage', console.log)
  
  window.localStorage.setItem('test', '123')
  window.dispatchEvent(new Event('storage'))

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
 <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
        {/* <List
              key={dasboard.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {dasboard.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: dasboard.items,
                pathname: location.pathname
              })}
            </List> */}
            <Box mt = {-1} />
            <List
              key={Users.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Users.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Users.items,
                pathname: location.pathname
              })}
            </List>
            <Box mt = {-1} />
            <List
            key={seetingsacademic.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {seetingsacademic.subheader}
              </ListSubheader>
            }
            >
            {renderNavItems({
              items: seetingsacademic.items,
              pathname: location.pathname
            })}
            </List> 
        </Box>
        <Divider />
        </PerfectScrollbar>

        <Box flexGrow={1} />
       
        <Divider />
       

    </Box>
  );

  const Rightcontent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {sections.map(section => (
            <Box>
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderRightNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>

            <List
            key={seetingsacademic.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {seetingsacademic.subheader}
              </ListSubheader>
            }
            >
            {renderNavItems({
              items: seetingsacademic.items,
              pathname: location.pathname
            })}
            </List>
            </Box>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const Logocontent = (
    <Box height="100%" display="flex" flexDirection="column" >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
       
        <Divider />
     
        <Box p={2}>
        </Box>
        <Divider />
        <Box p={2} flexDirection="column" justifyContent="flex-end">
          <Box flexGrow={1} />
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              // component={RouterLink}
              // to="/docs"
              className={classes.action}
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );


  return (
    <div className={classes.root}>
      <CssBaseline />
      <ColorAppBar className={clsx(classes.root, className)} {...rest} >
        <Toolbar className={classes.toolbar}>
        {open === true && (
            <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, open)}
            >
            <MenuIcon fontSize = "large" />
            </IconButton>
          )}
         {open === false && (
            <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, open)}
            >
            <MenuOpenIcon fontSize = "large" />
            </IconButton>
          )}
         
          <Hidden mdDown>
          <Avatar
          src= {"/static/company.png"}
          ></Avatar>
          <Box mr = {1} />
         
        </Hidden>
         
         
          <Box ml={2} flexGrow={1} />
          {/* <Search /> */}
          {/* <Contacts /> */}
         <Notifications />
          {/*  <Settings /> */}
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </ColorAppBar>
      <Drawer
        classes={{ paper: classes.desktopDrawer }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {content}
      </Drawer>
     {/* {open === false && (
        <Hidden mdDown>
       <Drawer
       classes={{ paper: logoClasses.desktopDrawer }}
       variant="persistent"
       anchor="left"
       open={true}
     >
       {Logocontent}
     </Drawer>
     </Hidden>
     )} */}
      {/* <Drawer
        classes={{ paper: rightClasses.desktopDrawer }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        {Rightcontent}
      </Drawer> */}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
