import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem,
  makeStyles,
  Typography,
  Paper,
  Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: '17px'
  },
  button: {
    color: theme.palette.text.dark,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
   
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    // boxShadow:'5px 5px 10px #ccc',
    //fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        //fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto',
    textTransform: 'none',
  },
  active: {
    color: theme.palette.secondary.main,
    //fontWeight: 'fontWeightMedium',
    color: theme.palette.primary.main,
    backgroundColor: '#eeeeee',
    
    '& $title': {
      //fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };
  const styletypo = { fontSize: '18px' ,fontWeight : 400};

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        
        <Box
          className={classes.button}
          onClick={handleToggle}
              boxShadow={'5px 5px 10px #ccc'}

          style = {{
            // borderRadius: 28,
            paddingLeft : paddingLeft,
            // fontSize: '28px',
            fontWeight: 0,
            elevation :16
          }}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="30"
            />
          )}
           <Typography  style={styletypo}>
            {title}
          </Typography>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
      style = {{
        boxShadow : 12
      }}
    >
      
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
      
        to={href}
        style = {{
          // borderRadius: 28,
          elevation:5,
          paddingLeft : paddingLeft,
          fontSize: '100px',
          boxShadow : 12,
          fontWeight: 0,
        }}
              boxShadow={'5px 5px 10px #ccc'}

      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="30"
          />
        )}
        
        <Typography  style={styletypo}>
          {title}
        </Typography>
        
        {Info && <Info />}
      </Button>
    
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
