// import React, {
//   useRef,useEffect,
//   useState,useCallback
// } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
// import { useHistory } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
// import {
//   Avatar,
//   Box,
//   ButtonBase,
//   Hidden,
//   Drawer,
//   Typography,
//   IconButton,
//   Tooltip,
//   Card,
//   Divider,
//   Dialog,
//   Button,
//   makeStyles
// } from '@material-ui/core';
// import useAuth from 'src/hooks/useAuth';
import getInitials from 'src/utils/getInitials';
import getName from 'src/utils/getName';
// import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
 import ChangePassword from 'src/views/auth/LoginView/Change_Password';
// import gql from 'graphql-tag';
// import {client} from 'src/utils/GQLInstitutionClient';
// import useIsMountedRef from 'src/hooks/useIsMountedRef';
// import AWS from 'aws-sdk';

// const useStyles = makeStyles((theme) => ({
//   avatar: {
//     height: 32,
//     width: 32,
//     marginRight: theme.spacing(1)
//   },
//   popover: {
//     width: 200
//   },
//   wrapper: {
 
//   },
//   contentContainer: {
//     flex: '1 1 auto',
//     overflow: 'auto'
//   },
//   wrapper1: {
//     paddingTop: 0
//   },
//   drawer: {
//     width: '33%',
//     maxWidth: '100%',
 
//   },
//   avatar1: {
//     height: 60,
//     width: 60
//   },
//   name: {
//     marginTop: theme.spacing(2)
//   },
//   listbox : {
//     backgroundColor: "#e0e0e0"
//   },
//   divider : {
//     backgroundColor:   "#283593",
//     height : 4
//   },
//   middledivider : {
//     height : 2
//   }
// }));

// const noPointer = {cursor: 'pointer'};

// const Account = () => {
//   const classes = useStyles();
//   const history = useHistory();
//   const ref = useRef(null);
//   const { user, logout, image } = useAuth();
//   const { enqueueSnackbar } = useSnackbar();
//   const [isOpen, setOpen] = useState(false);
//   const isMountedRef = useIsMountedRef();

//   const [users , setUsers] = useState([])
//   const getCustomers = useCallback(async () => {
//     try {
//       const getapps = gql`
//       query MyQuery( 
//         $user_email : String!
   
//         ){
//           get_user_by_email(  user_email : $user_email) {
          
//             _id
//             first_name
//             last_name
//             user_email
//             user_role
//             institution_name
//             institution_id
//             organization_id
//             organization_name
//             status
//             user_contact
//             user_image
//             user_id
//             user_dept
//             user_department
//             message
//             user_apps{
//               app_status
//             }
//           }
//       }
//   `;
 
//       const { data } = await 
//       client.query({
//           query: getapps,
//           variables: {
//             user_email :  user.email,
          
//           },
//             fetchPolicy: 'network-only'
//         })
//         .then(res => {
//           console.log(res);
//           return res;
//         });
      
//         console.log(data);
 
 
//        if (isMountedRef.current) {
 
 
//         setUsers(data.get_user_by_email);
//         getCustomers1(data.get_user_by_email._id)
     
      
//        }
//     } catch (err) {
//       console.error(err);
//     }
//   }, [isMountedRef]);
 
//   useEffect(() => {
//     getCustomers();
//   }, [getCustomers]);


//   const [Image, setImage] = useState(null);
//   let bucketName = "erpusers";
//   var imageFolderPhotosKey = encodeURIComponent("employees") + "/" + encodeURIComponent("profile_picture") + "/"
 
//   const s3 = new AWS.S3();
//   const tobase64 = (u8) => {
//     return btoa(String.fromCharCode.apply(null, u8));
//   }

//   const getCustomers1 = async (id) => {
//     try {
//       var photoKey = imageFolderPhotosKey + id
//         var img = await s3.getObject({
//           Bucket: bucketName,
//           Key: photoKey,
//         }).promise().then(res => {
//           if(res){
//             console.log(res)
//             return res;
//           } else {
//             return null;
//           }
//         }).catch(err => {
//           console.log(err)
//         });

//         if( img === undefined ){
//           setImage(null);
//         } else {
//           console.log(img)
//           var base64Flag = `data:${img.ContentType};base64,`;
         
//           var imgConvD = tobase64(img.Body)
//           setImage(base64Flag + imgConvD);
//         }
      
      
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   const [open, setOpen1] = useState(false);
//   const handleClickOpen = () => {
//     setOpen1(true);
//   };
//   const handleClickClose = () => {
//     setOpen1(false);
//   };
 

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleLogout = async () => {
//     try {
//       handleClose();
//       await logout();
//       history.push('/login');
//     } catch (err) {
//       console.error(err);
//       enqueueSnackbar('Unable to logout', {
//         variant: 'error'
//       });
//     }
//   };

//   return (
//     <>
//       <Box
//         display="flex"
//         alignItems="center"
//         onClick={handleOpen}
//         ref={ref}
//         style={noPointer}
//       >
//         <Avatar
//           alt="User"
//           className={classes.avatar}
//           src={Image}
//           >
//              {getInitials(`${user['custom:full_name']}`)}
//           </Avatar>
       
//         <Hidden smDown>
//           <Typography
//             variant="h6"
//             color="inherit"
//             color = "textPrimary"
//           >
           
//             {getName(`${user['custom:full_name']}`)} 
//           </Typography>
//         </Hidden>
//       </Box>

//       <Drawer
//         anchor="right"
//         classes={{ paper: classes.drawer }}
//         ModalProps={{ BackdropProps: { invisible: true } }}
//         onClose={handleClose}
//         open={isOpen}
//         variant="temporary"
//       >
       
//           <Box pt={3}     bgcolor="#e0e0e0" height = "auto">
//           <Box
//               display="flex"
//               justifyContent="space-between"
//               alignItems="center"
             
//             >
//               <Box flexGrow ={1} />
//               <Tooltip title="Exit">
//         <IconButton onClick ={handleClose}>
//         <CancelOutlinedIcon />
//       </IconButton>
//       </Tooltip>
//           </Box>
//           <Box
         
//           display="flex"
//           alignItems="center"
//           flexDirection="column"
//           textAlign="center"
//         >
//           <Avatar
//           alt="User"
//           className={classes.avatar1}
//           src={Image}
//           >
//              {getInitials(`${user['custom:full_name']}`)}
//           </Avatar>
         
//           <Typography
//             className={classes.name}
//             color="textPrimary"
           
//             variant="h3"
//           >
//             {getName(`${user['custom:full_name']}`)} 
//           </Typography>
        
//           <Typography
          
//             color="textPrimary"
           
//             variant="body1"
//           >
//            {user.email}
//           </Typography>
  
//           {/* <Typography
           
//             color="textPrimary"
           
//             variant="body1"
//           >
//             {user.Username}
//           </Typography> */}
        
//         </Box>
//           </Box>
//           <Box display ="flex" bgcolor="#e0e0e0" >
//           <Box flexGrow = {1} />
//           <Box mr = {1} mb ={1}>
//           <Button color="secondary" onClick = {handleClickOpen}>RESET PASSWORD</Button>
//           </Box>
//           <Box mr = {2} mb ={1}>
//           <Button color="secondary" onClick = {handleLogout}>SIGN OUT</Button>
//           </Box>
//           </Box>
//           <Divider className = {classes.divider}/>
//           {/* <PerfectScrollbar options={{ suppressScrollX: true }}>
//             <Box p={3}>
           
//               <Typography
//                 variant="h3"
//                 color="textPrimary"
//               >
//                 Subscriptions
//               </Typography>            
//             </Box>
//             <Divider variant="middle" className= {classes.middledivider}/>
           
//         </PerfectScrollbar> */}
//       </Drawer>
//       <Dialog
//           maxWidth="sm"
//           fullWidth
        
//           onClose={handleClickClose}
//           open={open}
//         >
//           <ChangePassword  open={handleClickClose} />
//         </Dialog>
  
//     </>
//   );
// }

// export default Account;


import React, {
 useRef,
 useState
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
 Avatar,
 Box,
 ButtonBase,
 Hidden,
 Menu,
 MenuItem,
 Typography,Dialog,
 makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
 avatar: {
   height: 32,
   width: 32,
   marginRight: theme.spacing(1)
 },
 popover: {
   width: 200
 }
}));

const Account = () => {
 const classes = useStyles();
 const history = useHistory();
 const ref = useRef(null);
 const { user, logout } = useAuth();
 const { enqueueSnackbar } = useSnackbar();
 const [isOpen, setOpen] = useState(false);

 const handleOpen = () => {
   setOpen(true);
 };

 const handleClose = () => {
   setOpen(false);
 };


 const [open, setOpen1] = useState(false);

  const handleClickOpen = () => {

  setOpen1(true);

  };

 const handleClickClose = () => {

  setOpen1(false);

  };

 const handleLogout = async () => {
   try {
     handleClose();
     await logout();
     history.push('/login');
   } catch (err) {
     console.error(err);
     enqueueSnackbar('Unable to logout', {
       variant: 'error'
     });
   }
 };

 return (
   <>
     <Box
       display="flex"
       alignItems="center"
       component={ButtonBase}
       onClick={handleOpen}
       ref={ref}
     >
       <Avatar
         alt="User"
         className={classes.avatar}
         src={Image}
         >
            {getInitials(`${user['custom:first_name']} ${user['custom:last_name']}`)}
         </Avatar>
       
       <Hidden smDown>
         <Typography
           variant="h6"
           color="inherit"
           
         >
           
           {getName(`${user['custom:first_name']}`)} {getName(`${user['custom:last_name']}`)} 
         </Typography>
       </Hidden>
     </Box>
     <Menu
       onClose={handleClose}
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'center'
       }}
       keepMounted
       PaperProps={{ className: classes.popover }}
       getContentAnchorEl={null}
       anchorEl={ref.current}
       open={isOpen}
     >
       <MenuItem
         onClick = {handleClickOpen}
       >
         Reset Password
       </MenuItem>
       {/* <MenuItem
         component={RouterLink}
         to="/app/account"
       >
         Account
       </MenuItem> */}
       <MenuItem onClick={handleLogout}>
         Logout
       </MenuItem>
     </Menu>
     <Dialog
          maxWidth="sm"
          fullWidth
     
          onClose={handleClickClose}
          open={open}
        >
          <ChangePassword  open={handleClickClose} />
        </Dialog>
   </>
 );
}

export default Account;

